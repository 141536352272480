/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { dataActions } from "../../shared/store/data-Slice";
import CityLayout from "./CityLayout";
import NotFound from "../../pages/404/NotFound";
import { url } from "../../shared/utils/url";
const StationDetail = React.lazy(() => import("../../pages/stationDetail/StationDetail"));
const StationLine = React.lazy(() => import("../../pages/stationLine/StationLine"));
const Home = React.lazy(() => import("../../pages/city/City"));
const KnowStation = React.lazy(() => import("../../pages/knowStation/KnowStation"));
const LostFound = React.lazy(() => import("../../pages/lostFound/LostFound"));
const Map = React.lazy(() => import("../../pages/map/Map"));
const Tenders = React.lazy(() => import("../../pages/tenders/Tenders"));
const ArchivedTenders = React.lazy(() => import("../../pages/tenders/ArchivedTenders"));
const Operations = React.lazy(() => import("../../pages/tenders/Operations"));
const PropertyDevelopment = React.lazy(() => import("../../pages/tenders/PropertyDevelopment"));
const Tours = React.lazy(() => import("../../pages/tours/Tours"));
const Content = React.lazy(() => import("../../pages/content/Content"));
const Sitemap = React.lazy(() => import("../../pages/sitemap/Sitemap"));
const ScreenReaderAccess = React.lazy(() => import("../../pages/screenReader/ScreenReader"));
const MetroMarvel = React.lazy(() => import("../../pages/metroMarvel/MetroMarvel"));
const Search = React.lazy(() => import("../../pages/search/Search"));
const ContactUs = React.lazy(() => import("../../pages/contactus/ContactUs"));
const ComingSoon = React.lazy(() => import("../../pages/comingSoon/ComingSoon"));
const GoSmartCard = React.lazy(() => import("../../pages/goSmartCard/GoSmartCard"));
const PressRelease = React.lazy(() => import("../../pages/content/PressRelease"));
const ContentDetail = React.lazy(() => import("../../components/content/ContentDetail"));

const City = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        let host: string[] = window.location.hostname.split(".");
        if (host?.length > 0) {
            dispatch(dataActions.setCurrentCity(host?.includes("www") ? (url?.env === "live" ? host[1] : ((host[1]?.split("-"))?.[0])) : (url?.env === "live" ? host[0] : ((host[0]?.split("-"))?.[0]))))
        }
    }, [window.location.hostname])

    return (
        <Routes>
            <Route element={<CityLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/:lang" element={<Home />} />
                <Route path="/site-map" element={<Sitemap />} />
                <Route path="/:lang/site-map" element={<Sitemap />} />
                <Route path="/screen-reader-access" element={<ScreenReaderAccess />} />
                <Route path="/:lang/screen-reader-access" element={<ScreenReaderAccess />} />
                <Route path="/line/:name" element={<StationLine />} />
                <Route path="/:lang/line/:name" element={<StationLine />} />
                <Route path="/station/:id" element={<StationDetail />} />
                <Route path="/:lang/station/:id" element={<StationDetail />} />
                <Route path="/know-station/:facility" element={<KnowStation />} />
                <Route path="/know-station/:facility/:keyword" element={<KnowStation />} />
                <Route path="/:lang/know-station/:facility" element={<KnowStation />} />
                <Route path="/:lang/know-station/:facility/:keyword" element={<KnowStation />} />
                <Route path="/lost-found" element={<LostFound />} />
                <Route path="/lost-found/:station" element={<LostFound />} />
                <Route path="/lost-found/:station/:search" element={<LostFound />} />
                <Route path="/:lang/lost-found" element={<LostFound />} />
                <Route path="/:lang/lost-found/:station" element={<LostFound />} />
                <Route path="/:lang/lost-found/:station/:search" element={<LostFound />} />
                <Route path="/map" element={<Map />} />
                <Route path="/:lang/map" element={<Map />} />
                <Route path="/tenders" element={<Tenders />} />
                <Route path="/:lang/tenders" element={<Tenders />} />
                <Route path="/tenders/:category/:subcategory" element={<Tenders />} />
                <Route path="/:lang/tenders/:category/:subcategory" element={<Tenders />} />
                <Route path="/operations" element={<Operations />} />
                <Route path="/:lang/operations" element={<Operations />} />
                <Route path="/operations/:category/:subcategory" element={<Operations />} />
                <Route path="/:lang/operations:category/:subcategory" element={<Operations />} />
                <Route path="/property-development" element={<PropertyDevelopment />} />
                <Route path="/:lang/property-development" element={<PropertyDevelopment />} />
                <Route path="/property-development/:category/:subcategory" element={<PropertyDevelopment />} />
                <Route path="/:lang/property-development:category/:subcategory" element={<PropertyDevelopment />} />
                <Route path="/archived-tenders" element={<ArchivedTenders />} />
                <Route path="/:lang/archived-tenders" element={<ArchivedTenders />} />
                <Route path="/archived-tenders/:category/:subcategory" element={<ArchivedTenders />} />
                <Route path="/:lang/archived-tenders/:category/:subcategory" element={<ArchivedTenders />} />
                <Route path="/tours" element={<Tours />} />
                <Route path="/:lang/tours" element={<Tours />} />
                <Route path="/metro-marvel" element={<MetroMarvel />} />
                <Route path="/:lang/metro-marvel" element={<MetroMarvel />} />
                <Route path="/search/:search" element={<Search />} />
                <Route path="/:lang/search/:search" element={<Search />} />
                <Route path="/pages/:slug" element={<Content />} />
                <Route path="/:lang/pages/:slug" element={<Content />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/:lang/contact-us" element={<ContactUs />} />
                <Route path="/smart-card" element={<GoSmartCard />} />
                <Route path="/:lang/smart-card" element={<GoSmartCard />} />
                <Route path="/pages/press-releases" element={<PressRelease />} />
                <Route path="/:lang/pages/press-releases" element={<PressRelease />} />
                <Route path="/pages/press-releases/:slug" element={<ContentDetail />} />
                <Route path="/:lang/pages/press-releases/:slug" element={<ContentDetail />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/:lang/coming-soon" element={<ComingSoon />} />
            </Route>
            <Route path="/404" element={<NotFound />} />
            <Route path="/:lang/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default City;