import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import { dataActions } from "../../shared/store/data-Slice";
import { useTranslation } from "react-i18next";
import { CloseDarkBtn } from "../svg/AllSvg";

const WebPopup = () => {
    const webOpen: boolean = useSelector((state: any) => state.ui.webOpen);
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    const link: string = useSelector((state: any) => state.data.link);
    const color: any = useSelector((state: any) => state.ui.color);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(uiActions.setWebOpen(false))
        }
    };

    const handleLink = () => {
        if (!!link) {
            window.open(link, '_blank');
            dispatch(uiActions.setWebOpen(false));
            dispatch(dataActions.setLink(null));
        }
    }

    return (
        <>
            {webOpen &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(88,88,88,0.40)] backdrop-blur-[2.5px]" onClick={() => dispatch(uiActions.setWebOpen(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="md:mx-auto bg-white dark:bg-[#333333] rounded-[10px] flex flex-col items-center justify-center w-[275px] ss:w-[320px] h-auto sm:w-[446px] z-[3] relative p-[30px]">
                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="cursor-pointer flex items-center justify-center opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" onClick={() => dispatch(uiActions.setWebOpen(false))}>
                                <CloseDarkBtn />
                            </button>

                            <img src={require("../../assets/images/Mask group35.png")} alt="pattern" className="absolute left-0 bottom-0 z-[1] sm:w-auto sm:h-auto w-[200px] h-[200px]" />

                            <img src={require("../../assets/images/web.png")} alt="web" />

                            <p className="text-secondary text-xs sm:text-sm 1xl:text-base font-normal max-w-[359px] text-center pt-[31px]">{t("popup.web_desc")}</p>

                            <button style={{ background: `linear-gradient(80deg, ${!!currentCity ? `${color[currentCity]?.primary}` : "#5C0054"} -0.21%, ${!!currentCity ? `${color[currentCity]?.secondary}` : "#A71099"} 86.05%)` }} className="text-white text-sm font-extrabold capitalize   w-[200px] min-h-[40px] flex items-center justify-center mt-[18px] rounded-[14px] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] focus:shadow-[0_0_6px_0px_#32435F] hover:shadow-[0_0_6px_0px_#32435F] z-[1]" onClick={handleLink}>{t("popup.continue")}</button>

                            <button className="border-0   text-[#C6C6C6] text-sm font-extrabold w-[200px] flex items-center justify-center py-[10px] mt-[10px] focus:shadow-[0_0_6px_0px_#32435F] hover:shadow-[0_0_6px_0px_#32435F] rounded-[14px] z-[1]" onClick={() => dispatch(uiActions.setWebOpen(false))}>{t("popup.cancel")}</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default WebPopup;