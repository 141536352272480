/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Facebook, Twitter, Youtube } from "../svg/AllSvg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Moment from 'moment';
import { uiActions } from "../../shared/store/ui-Slice";
import { dataActions } from "../../shared/store/data-Slice";
import useAxios from "../../shared/hooks/use-axios";
import { url } from '../../shared/utils/url';

const Footer = () => {
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    const color: any = useSelector((state: any) => state.ui.color);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const cities: any = useSelector((state: any) => state.data.cities);
    const hicities: any = useSelector((state: any) => state.data.hicities);
    const pageData: any = useSelector((state: any) => state.data.pageData);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const [footerMenuData, setFooterMenuData] = useState<any[]>([]);
    const [visitorCounter, setVisitorCounter] = useState<any>('');
    const [selectCity, setSelectCity] = useState<any>('');
    const params = useParams();
    const navigate = useNavigate();

    const handleLink = (item: any) => {
        dispatch(uiActions.setWebOpen(true));
        dispatch(dataActions.setLink(item));
    }

    const getCityData = async () => {
        try {
            dispatch(dataActions.setIsLoading(true));
            const res: any = await useAxios.get(`/${lang + url?.city}`);

            if (res && res?.status === 200) {
                dispatch(dataActions.setIsLoading(false));
                let obj: any = {};
                for (let i = 0; i < res?.data?.length; i++) {
                    const el = res?.data[i];
                    obj[el?.code] = el?.name
                }

                dispatch(dataActions.setCitiesData(res?.data))
                dispatch(dataActions.setHiCities(obj))
            }
        } catch (error) {
            dispatch(dataActions.setIsLoading(false));
            console.error("error while feching city data", error);
        }
    };

    const getComplaintCategoryData = async () => {
        try {
            dispatch(dataActions.setIsLoading(true));
            const res: any = await useAxios.get(`/${lang + url?.complaintCategories}`);

            if (res && res?.status === 200) {
                dispatch(dataActions.setIsLoading(false));

                let categoriesArray: any = [];
                for (var i = 0; i < res?.data?.results?.length; i++) {
                    categoriesArray.push({
                        label: res?.data?.results[i]?.name,
                        value: res?.data?.results[i]?.id
                    });
                }

                dispatch(dataActions.setComplaintCategoryData(categoriesArray))
            }
        } catch (error) {
            dispatch(dataActions.setIsLoading(false));
            console.error("error while feching complaint category data", error);
        }
    };

    useEffect(() => {
        !!lang && getCityData()
    }, [lang])

    const getFooterMenu = async () => {
        try {
            dispatch(dataActions.setIsLoading(true));
            const res: any = await useAxios.get(`/${lang + url?.footerMenu}`);

            if (res && res?.status === 200) {
                setFooterMenuData(res?.data)
                dispatch(dataActions.setIsLoading(false));
            }
        } catch (error) {
            dispatch(dataActions.setIsLoading(false));
            console.error("error while feching event data", error);
        }
    };

    const getVisitorCounter = async () => {
        try {
            dispatch(dataActions.setIsLoading(true));
            const res: any = await useAxios.get(`${url?.visitorCounter}`);

            if (res && res?.status === 200) {
                setVisitorCounter(res?.data?.results?.visitors_count)
                dispatch(dataActions.setIsLoading(false));
            }
        } catch (error) {
            dispatch(dataActions.setIsLoading(false));
            console.error("error while feching event data", error);
        }
    };

    const postVisitorCounter = async () => {
        try {
            const res: any = await useAxios.post(`${url?.visitorCountUpdate}`, { counter: !!!localStorage.getItem("visitor_count") ? 1 : 0 });

            if (res && res?.status === 200) {
                localStorage.setItem("visitor_count", "1")
                getVisitorCounter()
            }
        } catch (error) {
            console.error("error while increase visitor counter", error);
        }
    };

    useEffect(() => {
        postVisitorCounter();
        getVisitorCounter();
    }, [])

    useEffect(() => {
        !!lang && getFooterMenu();
        !!lang && getComplaintCategoryData();
    }, [lang])

    const getCity = () => {
        let arr: any = [];

        let index = 0;
        for (const key in cities) {
            arr.push(
                <div key={index} className="group w-full rounded-[10px] city-line relative z-[2]">
                    <button onClick={() => navigateCity(key)} className={`${key === selectCity ? "border-[#008A93] shadow-[0px_8px_16px_-6px_rgba(24,39,75,0.08),0px_6px_8px_-6px_rgba(24,39,75,0.12)]" : "border-[#EAEAEA] bg-white"} border rounded-[10px] h-[30px] flex items-center relative pl-[5px] cursor-pointer group-hover:border-[#008A93] z-[2]  focus:shadow-[0_0_6px_0px_#d6d6d6] w-full`} key={index}>
                        <h3 className={`${key === selectCity ? "text-primary dark:text-white" : "text-darkGray"} xxl:pr-[50px] text-xs font-bold capitalize group-hover:text-primary relative z-[2]`}>{!!hicities && hicities?.[key]}</h3>
                        <img src={require(`../../assets/images/${key}.png`)} alt="line" className={`absolute top-0 right-0 bottom-0 ${selectCity !== key && "city-line-img"}`} />
                    </button>
                </div>
            );
            index++;
        }

        return arr;
    };

    const goToHome = () => {
        if (!!currentCity) {
            let tenant: any = url?.env === "live" ? window.location.origin?.replace(`${currentCity}.`, "") : window.location.origin?.replace(`${currentCity}-`, "")
            window.location.href = !!params?.lang ? `${tenant}/${params?.lang}` : `${tenant}`;
        } else {
            !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
        }
    }

    const navigateCity = async (code: any) => {
        // code to stop scrolling when redirecting
        if (currentCity) {
            let tenant: any = url?.env === "live" ? window.location.origin?.replace(`${currentCity}.`, code + '.') : window.location.origin?.replace(`${currentCity}`, code)
            if (params?.lang) {
                window.location.assign(`${tenant}/${params?.lang}`);
            } else {
                window.location.assign(tenant);
            }
        } else {
            const protocol = window.location.protocol;
            const www = window.location.host?.includes("www.") ? true : false;
            const host = www ? window.location.host?.replace("www.", "") : window.location.host;
            const newUrl: any = url?.env === "live" ? (protocol + "//" + code + "." + host) : (protocol + "//" + code + "-" + host);
            if (params?.lang) {
                window.location.assign(`${newUrl}/${params?.lang}`);
            } else {
                window.location.assign(newUrl);
            }
        }
    }

    const handleNavigate = (menu: any) => {
        if (!menu?.url?.includes("survey")) {
            if (menu?.url?.includes("about")) {
                if (!!currentCity) {
                    let tenant: any = url?.env === "live" ? window.location.origin?.replace(`${currentCity}.`, "") : window.location.origin?.replace(`${currentCity}-`, "")
                    window.location.href = !!params?.lang ? `${tenant}/${params?.lang}/about/about-upmrcl` : `${tenant}/about/about-upmrcl`;
                } else {
                    !!params?.lang ? navigate(`/${params?.lang}/about/about-upmrcl`) : navigate(`/about/about-upmrcl`)
                }
            } else {
                navigate(!!params?.lang ? `/${params?.lang + menu?.url?.replace(`/${lang}/`, "")}` : `${menu?.url?.replace(`/${lang}/`, "")}`)
            }
        }
    }

    return (
        <div className="w-full h-full relative z-[0] bg-white dark:bg-[#272727] dark:text-white">
            {!window.location.pathname?.includes("/map") && <p className="container dark:text-white text-xs sm:text-sm text-[#6D6D6D] font-normal pb-[15px]">{t("footer.last_updated_on")} - {Moment(pageData?.updated_at).format('DD MMM YYYY')}</p>}

            <img src={!!currentCity ? require(`../../assets/images/${currentCity}/pattern7.png`) : require("../../assets/images/Mask group1.png")} alt="pattern" className="w-full h-[25px] lg:h-auto object-cover" />


            <div style={{ background: `linear-gradient(80deg, ${!!currentCity ? `${color[currentCity]?.primary}` : "#5C0054"} -0.21%, ${!!currentCity ? `${color[currentCity]?.secondary}` : "#A71099"} 86.05%)` }} className="w-full relative pb-[30px] pt-[60px] md:py-[60px] md:!pb-0 1xl:py-[70px] 1xl:!pb-0 2xl:pt-[95px] flex flex-col z-[2]">
                <img src={!!currentCity ? require(`../../assets/images/${currentCity}/pattern11.png`) : require("../../assets/images/Mask group8.png")} alt="pattern" className="absolute z-[1] right-0 top-0 mix-blend-multiply" />

                <div className="absolute top-0 left-0 right-0 bottom-0 flex items-start justify-center dark:bg-[#272727] dark:text-white">
                    <div className="rounded-[15px] rounded-t-none backdrop-blur-[5.5px] bg-[rgba(255,255,255,0.10)] h-[35px] flex items-center justify-center text-white text-xs sm:text-sm font-normal px-[20px] lg:px-[40px] dark:text-[#ffff00]">
                        {t("footer.visitor_no")} - {visitorCounter}
                    </div>
                </div>

                <div className="container w-full h-full z-[2]">
                    <div className="flex lg:items-start flex-col lg:flex-row lg:justify-between w-full gap-y-[20px] lg:gap-x-[25px]">
                        <div className="flex flex-col items-start">
                            <img src={require("../../assets/images/UPMRCL 1.png")} alt="Uttar Pradesh Metro Rail Corporation Limited" />
                            <div className="flex items-center gap-[12px] pt-[21px]">
                                <div className="group">
                                    <Link aria-label="Facebook" title='Facebook' to="https://www.facebook.com/officialupmetro/" className="cursor-pointer group-hover:bg-white group-hover:rounded-full w-[35px] h-[35px] flex items-center justify-center  focus:rounded-full focus:shadow-[0_0_6px_0px_#fff]" target="_blank" onClick={(e) => { handleLink("https://www.facebook.com/officialupmetro/"); e.preventDefault(); }}>
                                        <Facebook className="w-[18px] h-[18px] 1xl:!w-[20px] 1xl:!h-[20px] 2xl:!w-auto 2xl:!h-auto fill-white group-hover:fill-primary" />
                                    </Link>
                                </div>
                                <div className="group">
                                    <Link aria-label="Twitter" title='Twitter' to="https://twitter.com/officialupmetro/" className="cursor-pointer group-hover:bg-white group-hover:rounded-full w-[35px] h-[35px] flex items-center justify-center  focus:rounded-full focus:shadow-[0_0_6px_0px_#fff]" target="_blank" onClick={(e) => { handleLink("https://twitter.com/officialupmetro/"); e.preventDefault(); }}>
                                        <Twitter className="w-[18px] h-[18px] 1xl:!w-[20px] 1xl:!h-[20px] 2xl:!w-auto 2xl:!h-auto fill-white group-hover:fill-primary" />
                                    </Link>
                                </div>
                                <div className="group">
                                    <Link aria-label="Youtube" title='Youtube' to="https://www.youtube.com/@OfficialUPMetro" className="cursor-pointer group-hover:bg-white group-hover:rounded-full w-[35px] h-[35px] flex items-center justify-center  focus:rounded-full focus:shadow-[0_0_6px_0px_#fff]" target="_blank" onClick={(e) => { handleLink("https://www.youtube.com/@OfficialUPMetro"); e.preventDefault(); }}>
                                        <Youtube className="w-[18px] h-[18px] 1xl:!w-[20px] 1xl:!h-[20px] 2xl:!w-auto 2xl:!h-auto fill-white group-hover:fill-primary" />
                                    </Link>
                                </div>
                            </div>

                            <div className="flex items-center gap-[10px] pt-[20px]">
                                <Link aria-label="Uttar Pradesh Government" title='Uttar Pradesh Government' to="https://www.india.gov.in/" target='_blank' className=" focus:rounded-[12px] focus:shadow-[0_0_6px_0px_#fff] p-[10px]">
                                    <img src={require("../../assets/images/logo_en 1.png")} alt="Uttar Pradesh Government" className="object-cover" onClick={(e) => { handleLink("https://www.india.gov.in/"); e.preventDefault(); }} />
                                </Link>
                                <Link aria-label="Digital India" title='Digital India' to="https://digitalindia.gov.in/" target='_blank' className=" focus:rounded-[12px] focus:shadow-[0_0_6px_0px_#fff] p-[10px]" onClick={(e) => { handleLink("https://digitalindia.gov.in/"); e.preventDefault(); }}>
                                    <img src={require("../../assets/images/kisspng-digital-india-government-of-india-indian-institute-qutub-minar-5b55b542bf86d6 1.png")} alt="Digital India" className="object-cover" />
                                </Link>
                                <Link aria-label="My Government" title='My Government' to="https://www.mygov.in/" target='_blank' className=" focus:rounded-[12px] focus:shadow-[0_0_6px_0px_#fff] p-[10px]" onClick={(e) => { handleLink("https://www.mygov.in/"); e.preventDefault(); }}>
                                    <img src={require("../../assets/images/Mygov 1.png")} alt="My Government" className="object-cover" />
                                </Link>
                            </div>
                        </div>

                        <div className="w-full lg:w-auto lg:gap-x-[16px] xl:gap-x-0 xxl:gap-x-[20px] xl:w-[800px] 2xl:w-[900px] 4xl:w-[1050px] grid grid-cols-2 xs:grid-cols-3 md:grid-cols-5 gap-y-[20px] md:gap-y-0">
                            <div className='w-fit h-fit border-r border-white border-opacity-[0.2] pr-[22%] ss:pr-[30%] xs:pr-[26%] md:pr-[22%] mm:pr-[28%] lg:pr-[25%] xl:pr-[20%] 2xl:pr-[23%] 4xl:pr-[28%]'>
                                <div className="flex flex-col gap-y-[15px] w-fit">
                                    <div className="flex flex-col items-start gap-[15px]">
                                        <h1 className="text-sm 1xl:text-base font-bold text-white font-caudex dark:text-[#ffff00]">
                                            {t("footer.switch_page")}
                                        </h1>
                                        <div className="border-b-[2px] border-white w-[37px]"></div>
                                    </div>
                                    <div className="flex flex-col gap-[13px]">
                                        <div className="group w-full rounded-[10px] city-line relative z-[2]">
                                            <button
                                                className={"border-[#EAEAEA] bg-white border rounded-[10px] h-[30px] flex items-center relative pl-[5px] cursor-pointer group-hover:border-[#008A93] z-[2]  focus:shadow-[0_0_6px_0px_#d6d6d6] w-full"}
                                                onClick={goToHome}
                                                tabIndex={0} // Make the button focusable via keyboard navigation
                                            >
                                                {/* Use a span instead of h3 for accessibility */}
                                                <span className="text-darkGray text-xs font-bold capitalize group-hover:text-primary relative z-[2]">
                                                    {t('content_page.home')}
                                                </span>
                                                {/* Add alt text for the image */}
                                                <img
                                                    src={require("../../assets/images/home.png")}
                                                    alt="Home Icon"
                                                    className="absolute top-1 right-0 bottom-0 city-line-img"
                                                />
                                            </button>
                                        </div>
                                        {getCity()}
                                    </div>
                                </div>
                            </div>

                            {Array.isArray(footerMenuData) && footerMenuData?.map((item: any, index: number) => (
                                <div key={index} className="flex flex-col gap-y-[15px]">
                                    <div className="flex flex-col items-start gap-[15px]">
                                        <h1 className="text-sm 1xl:text-base font-bold text-white font-caudex dark:text-[#ffff00]">{item?.title}</h1>
                                        <div className="border-b-[2px] border-white w-[37px]"></div>
                                    </div>
                                    {Array.isArray(item?.subRoutes) && item?.subRoutes?.map((subItem: any, idx: number) => (
                                        <React.Fragment key={idx}>
                                            {subItem.open_in_new_tab ?
                                                <Link to={`${subItem?.url?.replace(`/${lang}/`, "")}`} target='_blank' key={idx} className="text-white font-normal text-xs 1xl:text-sm white focus:underline hover:underline focus:text-opacity-[1] dark:text-[#ffff00]" onClick={(e) => { handleLink(subItem?.url?.replace(`/${lang}/`, "")); e.preventDefault(); }}>
                                                    {subItem?.title}
                                                </Link> :
                                                <Link to={subItem?.url?.includes("survey") ? `${url?.baseUrl}/${lang}/survey_forms/customer_satisfaction/` : (!!params?.lang ? `/${params?.lang + subItem?.url?.replace(`/${lang}/`, "")}` : `${subItem?.url?.replace(`/${lang}/`, "")}`)} key={idx} className="text-white font-normal text-xs 1xl:text-sm white focus:underline hover:underline focus:text-opacity-[1] dark:text-[#ffff00]" onClick={(e: any) => { handleNavigate(subItem); !subItem?.url?.includes("survey") && e.preventDefault(); }}>
                                                    {subItem?.title}
                                                </Link>
                                            }
                                        </React.Fragment>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mt-[20px] sm:mt-[30px] 1xl:mt-[50px] border-t-[0.5px] border-[#D9DBE9] w-full opacity-[0.6]" ></div>

                    <div className="flex items-center justify-between w-full pt-[15px] pb-[12px] z-[2] gap-[12px]">
                        <p className="text-white text-xs font-normal tracking-wide">{t("footer.copy_right")} © {new Date().getFullYear()} {t("footer.upmrc")}</p>
                        <p className="text-white text-xs font-normal tracking-wide">{t("footer.terms.all_right")} | <Link to={!!params?.lang ? `/${params?.lang}/pages/terms-of-use` : "/pages/terms-of-use"} className="text-[#ECECEC] text-xs font-normal focus:underline hover:underline">{t("footer.terms.terms_condition")}</Link> | <Link to={!!params?.lang ? `/${params?.lang}/pages/privacy-policy` : "/pages/privacy-policy"} className="text-[#ECECEC] text-xs font-normal focus:underline hover:underline">{t("footer.terms.privacy_policy")}</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;