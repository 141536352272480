import { I18nextProvider } from 'react-i18next';
import MainRouter from './router';
import i18n from './shared/utils/i18next';

const App = () => {
	return (
		<I18nextProvider i18n={i18n}>
			<MainRouter />
		</I18nextProvider>
	);
}

export default App;