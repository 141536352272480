import { createSlice } from '@reduxjs/toolkit';

interface UIState {
    lang: string,
    theme: any,
    font: number,
    langOpen: boolean,
    webOpen: boolean,
    page: string,
    color: any
}

const initialState: UIState = {
    lang: "",
    theme: localStorage.getItem("theme") || "light",
    font: 16,
    langOpen: false,
    webOpen: false,
    page: "",
    color: {
        "lucknow": {
            "primary": "#61095A",
            "secondary": "#8A0680",
            "textPrimary": "text-[#61095A]",
            "borderPrimary": "border-[#61095A]",
            "bgPrimary": "bg-[#61095A]",
            "afterBgPrimary": "after:bg-[#61095A]",
            "hoverTextPrimary": "hover:text-[#61095A]",
            "groupHoverTextPrimary": "group-hover:text-[#61095A]",
            "hoverBgPrimary": "hover:bg-[#61095A]",
            "groupHoverBgPrimary": "group-hover:bg-[#61095A]",
            "groupHoverBgSecondary": "group-hover:bg-[#8A0680]",
            "fillPrimary": "fill-[#61095A]",
            "strokePrimary": "stroke-[#61095A]",
            "focusTextPrimary": "focus:text-[#61095A]",
			"textTertiary": "text-[#5C0054]",
			"fillTertiary": "fill-[#5C0054]"

        },
        "agra": {
            "primary": "#003E4B",
            "secondary": "#008B94",
            "textPrimary": "text-[#003E4B]",
            "borderPrimary": "border-[#003E4B]",
            "bgPrimary": "bg-[#003E4B]",
            "afterBgPrimary": "after:bg-[#003E4B]",
            "hoverTextPrimary": "hover:text-[#003E4B]",
            "groupHoverTextPrimary": "group-hover:text-[#003E4B]",
            "hoverBgPrimary": "hover:bg-[#003E4B]",
            "groupHoverBgPrimary": "group-hover:bg-[#003E4B]",
            "groupHoverBgSecondary": "group-hover:bg-[#008B94]",
            "fillPrimary": "fill-[#003E4B]",
            "strokePrimary": "stroke-[#003E4B]",
            "focusTextPrimary": "focus:text-[#003E4B]",
			"textTertiary": "text-[#003E4B]",
			"fillTertiary": "fill-[#003E4B]"
        },
        "kanpur": {
            "primary": "#660205",
            "secondary": "#B03028",
            "textPrimary": "text-[#660205]",
            "borderPrimary": "border-[#660205]",
            "bgPrimary": "bg-[#660205]",
            "afterBgPrimary": "after:bg-[#660205]",
            "hoverTextPrimary": "hover:text-[#660205]",
            "groupHoverTextPrimary": "group-hover:text-[#660205]",
            "hoverBgPrimary": "hover:bg-[#660205]",
            "groupHoverBgPrimary": "group-hover:bg-[#660205]",
            "groupHoverBgSecondary": "group-hover:bg-[#B03028]",
            "fillPrimary": "fill-[#660205]",
            "strokePrimary": "stroke-[#660205]",
            "focusTextPrimary": "focus:text-[#660205]",
			"textTertiary": "text-[#660205]",
			"fillTertiary": "fill-[#660205]"
        }
    }
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        changeLang: (state, action) => {
            state.lang = action.payload;
        },
        changeTheme: (state, action) => {
            state.theme = action.payload;
        },
        changeFont: (state, action) => {
            state.font = action.payload;
        },
        setLangOpen: (state, action) => {
            state.langOpen = action.payload;
        },
        setWebOpen: (state, action) => {
            state.webOpen = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setColor: (state, action) => {
            state.color = action.payload;
        },
    }
});

export default uiSlice;
export const uiActions = uiSlice.actions;