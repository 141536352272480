import { createSlice } from '@reduxjs/toolkit';

interface DataState {
    homePageData: any,
    stationDetailData: any,
    cities: any,
    hicities: any,
    currentCity: string,
    stationStatus: any,
    selectTender: any,
    isLoading: boolean,
    link: any,
    cityPageData: any,
    helpLine: any,
    journeyPlan: any,
    routeData: any,
    routeApi: boolean,
    lineData: any,
    lostFound: boolean,
    loginOpen: boolean,
    registerOpen: boolean,
    profileOpen: boolean,
    profile: boolean,
    facilityApiCall: boolean,
    lostfoundApiCall: boolean,
    feedbackOpen: boolean,
    participateOpen: boolean,
    readMoreOpen: boolean,
    allCities: any,
    participateData: any,
    pageData: any,
    complaintCategories: any,
    loginWarnOpen: boolean,
    feedbackSuccess: boolean,
    complaintData: any,
    feedbackFormData: any,
    feedback: boolean
}

const initialState: DataState = {
    homePageData: null,
    stationDetailData: null,
    cities: null,
    hicities: null,
    currentCity: "",
    stationStatus: null,
    selectTender: null,
    isLoading: false,
    link: null,
    cityPageData: null,
    helpLine: null,
    journeyPlan: {
        from: '',
        fromName: '',
        to: '',
        toName: '',
        date: new Date()?.toString(),
        advanced: 'lsd',
        fromType: "",
        toType: ""
    },
    routeData: null,
    routeApi: true,
    lineData: [],
    lostFound: false,
    loginOpen: false,
    registerOpen: false,
    profileOpen: false,
    profile: false,
    facilityApiCall: true,
    lostfoundApiCall: true,
    feedbackOpen: false,
    participateOpen: false,
    readMoreOpen: false,
    allCities: null,
    participateData: null,
    pageData: null,
    complaintCategories: null,
    feedbackSuccess: false,
    loginWarnOpen: false,
    complaintData: null,
    feedbackFormData: null,
    feedback: false
}

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setHomePageData: (state, action) => {
            state.homePageData = action.payload;
        },
        setStationDetailData: (state, action) => {
            state.stationDetailData = action.payload;
        },
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        setHiCities: (state, action) => {
            state.hicities = action.payload;
        },
        setCurrentCity: (state, action) => {
            state.currentCity = action.payload;
        },
        setStationStatus: (state, action) => {
            state.stationStatus = action.payload;
        },
        setSelectTender: (state, action) => {
            state.selectTender = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLink: (state, action) => {
            state.link = action.payload;
        },
        setCityPageData: (state, action) => {
            state.cityPageData = action.payload;
        },
        setJourneyPlan: (state, action) => {
            state.journeyPlan = action.payload;
        },
        setRouteData: (state, action) => {
            state.routeData = action.payload;
        },
        setHelpLine: (state, action) => {
            state.helpLine = action.payload;
        },
        setRouteApi: (state, action) => {
            state.routeApi = action.payload;
        },
        setLineData: (state, action) => {
            state.lineData = action.payload;
        },
        setLostFound: (state, action) => {
            state.lostFound = action.payload;
        },
        setLoginOpen: (state, action) => {
            state.loginOpen = action.payload;
        },
        setRegisterOpen: (state, action) => {
            state.registerOpen = action.payload;
        },
        setProfileOpen: (state, action) => {
            state.profileOpen = action.payload;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        setFacilityApicall: (state, action) => {
            state.facilityApiCall = action.payload;
        },
        setLostfoundApiCall: (state, action) => {
            state.lostfoundApiCall = action.payload;
        },
        setFeedbackOpen: (state, action) => {
            state.feedbackOpen = action.payload;
        },
        setCitiesData: (state, action) => {
            state.allCities = action.payload;
        },
        setParticipateOpen: (state, action) => {
            state.participateOpen = action.payload;
        },
        setParticipate: (state, action) => {
            state.participateData = action.payload;
        },
        setReadMoreOpen: (state, action) => {
            state.readMoreOpen = action.payload;
        },
        setPageData: (state, action) => {
            state.pageData = action.payload;
        },
        setComplaintCategoryData: (state, action) => {
            state.complaintCategories = action.payload;
        },
        setFeedbackSuccess: (state, action) => {
            state.feedbackSuccess = action.payload;
        },
        setLoginWarnOpen: (state, action) => {
            state.loginWarnOpen = action.payload;
        },
        setComplaintData: (state, action) => {
            state.complaintData = action.payload;
        },
        setFeedbackFormData: (state, action) => {
            state.feedbackFormData = action.payload;
        },
        setFeedBack: (state, action) => {
            state.feedback = action.payload;
        },
    }
});

export default dataSlice;
export const dataActions = dataSlice.actions;