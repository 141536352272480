import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { useTranslation } from "react-i18next";
import { CloseBtn } from "../svg/AllSvg";

const FeedbackSuccess = () => {
    const feedbackSuccess: boolean = useSelector((state: any) => state.data.feedbackSuccess);
    // const complaintData: any = useSelector((state: any) => state.data.complaintData);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setFeedbackSuccess(false))
        }
    };

    return (
        <>
            {feedbackSuccess &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(88,88,88,0.40)] backdrop-blur-[2.5px]" onClick={() =>  dispatch(dataActions.setFeedbackSuccess(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[20px] w-full sm:w-[550px] h-full shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]">
                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => { dispatch(dataActions.setFeedbackSuccess(false)); }}>
                                <CloseBtn />
                            </button>
                            <div className="flex flex-col items-center justify-center px-[17px] pt-[40px] pb-[25px]">
                                <img src={require("../../assets/images/success.gif")} alt="success-gif" className="w-[132px] h-[132px] object-cover" />
                                <div className="px-[30px]">
                                    <h1 className="text-xl sm:text-2xl 1xl:text-3xl mt-[35px] text-[#61095A] font-bold font-caudex text-center">{t("home.feedback_success.title")}</h1>
                                    <h2 className="text-sm 2xl:text-base text-center mt-[8px] text-[#2D2D2D]">{t("home.feedback_success.desc")}</h2>
                                </div>

                                <div className="bg-[#d9d9d93b] rounded-[10px] px-[33px] py-[14px] w-full flex justify-between mt-[40px] gap-[10px] flex-wrap">
                                    <div className="flex flex-col">
                                        <label className="text-[#5C5C5C] text-sm fomt-normal" htmlFor="Complain ID">{t("home.feedback_success.id")}</label>
                                        <p className="text-primary dark:!text-primary text-sm font-bold">123456789</p>
                                    </div>

                                    <div className="flex flex-col">
                                        <label className="text-[#5C5C5C] text-sm fomt-normal" htmlFor="Status">{t("home.feedback_success.status")}</label>
                                        <p className="text-[#F00] text-sm font-bold">Pending</p>
                                    </div>

                                    <div className="flex flex-col">
                                        <label className="text-[#5C5C5C] text-sm fomt-normal" htmlFor="Date of complaint">{t("home.feedback_success.date")}</label>
                                        <p className="text-primary dark:!text-primary text-sm font-bold">22 Dec 2024</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default FeedbackSuccess;