export const url: any = {
    env: "prod",
    baseUrl: "https://upmrc-prod-admin.stagemyapp.com",
    // baseUrl: "https://upmrc-admin.stagemyapp.com",
    domain: {
        main: "upmrc-prod.stagemyapp.com",
        lucknow: "lucknow-upmrc-prod.stagemyapp.com",
        kanpur: "kanpur-upmrc-prod.stagemyapp.com",
        agra: "agra-upmrc-prod.stagemyapp.com"
    },
    // domain: {
    //     main: "upmrcl.com",
    //     lucknow: "lucknow.upmrcl.com",
    //     kanpur: "kanpur.upmrcl.com",
    //     agra: "agra.upmrcl.com"
    // },
    // domain: {
    //     main: "upmrc-prod.localhost",
    //     lucknow: "lucknow-upmrc-prod.localhost",
    //     kanpur: "kanpur-upmrc-prod.localhost",
    //     agra: "agra-upmrc-prod.localhost"
    // },
    lucknow_line: "LN2",
    agra_line: "LN1",
    kanpur_line: "LN3",
    home: "/api/v2/upmrc_homepage/",
    menu: "/api/v2/menus/",
    city: "/api/v2/cities_list/",
    line: "/api/v2/lines_list_by_city/",
    station: "/api/v2/stations_list_by_city_line/",
    stationDetail: "/api/v2/station_detail/",
    tenders: "/api/v2/tenders_by_category/",
    tendersCategory: "/api/v2/tenders_categories/",
    pages: "/api/v2/pages/",
    tours: "/api/v2/tour_guide/",
    city_page: "/api/v2/city_page/",
    searchStation: "/api/v2/stations_by_keyword/",
    searchStOrLt: "/api/v2/stations_localities_by_keyword/",
    updates: "/api/v2/line_detail/",
    station_brief_detail: "/api/v2/station_brief_detail/",
    travel_distance_time_fare: "/api/v2/travel_distance_time_fare/",
    station_route: "/api/v2/route/",
    facilityList: "/api/v2/facility_list/",
    stationsByFacility: "/api/v2/stations_list_by_facility/",
    stationsByLostFound: "/api/v2/lost_items/",
    ongoingEvents: "/api/v2/ongoing_events/",
    registration: "/api/v2/registration/",
    login: "/api/v2/login/",
    logout: "/api/v2/logout/",
    submitFeedback: "/api/v2/feedback_post/",
    footerMenu: "/api/v2/menus/footer-menu/",
    searchApi: "/api/v2/search_page/",
    notifications: "/api/v2/upmrc/notification/",
    eventPost: "/api/v2/event_participate_post/",
    mediaGallery: "/api/v2/media_gallery/",
    mediaGalleryDetails: "/api/v2/media_gallery_details/",
    contactUs: "/api/v2/contact_us/",
    pressRelease: "/api/v2/pressrelease/",
    pressReleaseDetails: "/api/v2/pressrelease_details/",
    visitorCounter: "/api/v2/total_visitors_count/",
    visitorCountUpdate: "/api/v2/visitor_count_update/",
    metroMarvel: "/api/v2/metro_marvel/",
    complaintCategories: "/api/v2/complaint_category/",
    complaintPost: "/api/v2/complaint_post/",
    notices: "/api/v2/notices_list/",
    get_sitemap: "",
    // cityWeather: `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_API_KEY}`
};