// NotFound.tsx
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import NotFoundIcon from "../../assets/images/404.png";

const NotFound = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('common');

	const handleNavigate = () => {
		navigate('/');
	}

	return (
		<div className="container flex flex-col justify-center items-center py-[60px] md:py-[123px]">
			<div className="col-span-1 flex justify-center">
				<img src={NotFoundIcon} alt='not-found' />
			</div>
			<div className="col-span-1 flex flex-col justify-center items-center mt-[41px]">
				<h2 className="text-primary text-base sm:text-lg 1xl:text-xl font-bold">{t("not_found.line1")}</h2>
				<h1 className="text-primary text-xl sm:text-2xl 1xl:text-3xl font-bold mt-[15px] font-caudex text-center">{t("not_found.line2")}</h1>
				<p className="text-sm sm:text-base text-center max-w-[660px] font-bold text-[#969696] mt-[15px]">{t("not_found.line3")}</p>
				<button className="rounded-[10px]  min-h-[40px] px-[45px] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold bg-primary mt-[30px] disabled:opacity-[0.3] disabled:cursor-not-allowed   focus:shadow-[0_0_6px_0px_#61095A] hover:shadow-[0_0_6px_0px_#61095A] uppercase" onClick={handleNavigate}>
					{t("not_found.home")}
				</button>
			</div>
		</div>
	);
};

export default NotFound;