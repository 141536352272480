export const useSelectPrimary = (theme: any): any => {
  let styles: any = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: "20 !important",
      borderRadius: "5px",
      background: "#fff",
      boxShadow: "0px 10px 32px -4px rgba(24, 39, 75, 0.10), 0px 6px 14px -6px rgba(24, 39, 75, 0.12)",
      top: "90%"
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: "700",
      color: state.isSelected ? "#61095A" : state.isFocused ? "#5A5A5A" : "#5A5A5A",
      backgroundColor: state.isSelected ? "#EDEDED" : state.isFocused ? "#EDEDED" : "#fff",
      cursor: "pointer",
      border: "none",
      "&:hover": {
        backgroundColor: state.isDisabled ? "normal" : "#EDEDED",
        border: "none",
        color: state.isDisabled ? "#686868" : state.isSelected ? "#61095A" : "#686868",
      },
      padding: "6px 12px"
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: `${theme === "dark" ? "white" : "#32435F"}`,
      fontSize: "12px",
      fontWeight: "700",
      width: "20px",
      margin: 0
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "5px"
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      paddingRight: "0"
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? `${theme === "dark" ? "white" : "#32435F"}` : `${theme === "dark" ? "white" : "#32435F"}`,
      fontSize: "12px",
      fontWeight: "700",
      "&:hover": {
        color: state.isFocused ? `${theme === "dark" ? "white" : "#32435F"}` : `${theme === "dark" ? "white" : "#32435F"}`,
      },
      padding: 0,
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: state.isFocused ? "0" : "0",
      backgroundColor: state.isFocused ? "none" : "none",
      borderRadius: state.isFocused ? "6px" : "6px",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        border: state.isFocused ? "0" : "0",
      },
      cursor: "pointer",
      minHeight: '18px',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: "#6B7280",
      fontSize: "12px",
      fontWeight: "400"
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: "#6B7280",
      fontSize: "12px",
      fontWeight: "700",
    }),
  };

  return styles;
};

export default useSelectPrimary;