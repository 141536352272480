/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { AutoNew, Speaker } from "../svg/AllSvg";
import { useTranslation } from "react-i18next";

const Captcha = ({ captchaValue, setCaptchaValue, captchaError, setCaptchaError, captchaExpirationTime, setCaptchaExpirationTime, captchaInput, setCaptchaInput }: any) => {
    const canvasRef = useRef(null);
    const { t } = useTranslation('common');

    const generateCaptcha = () => {
        const chars: any = process.env.REACT_APP_CAPTCHA_KEY;
        let captcha = "";
        for (let i = 0; i < 4; i++) {
            const randomChar = chars[Math.floor(Math.random() * chars.length)];
            captcha += randomChar;
        }
        setCaptchaInput("")
        return captcha;
    };

    const drawCaptcha = (captcha: any) => {
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Set canvas styles
        ctx.font = "28px Arial";
        ctx.fillStyle = "black";
        ctx.fillText(captcha, 10, 25); // Draw the CAPTCHA text
    };

    const speakCaptcha = (captcha: any) => {
        const utterance = new SpeechSynthesisUtterance(captcha);
        utterance.lang = "en-US";
        speechSynthesis.speak(utterance);
    };

    useEffect(() => {
        const newCaptcha = generateCaptcha();
        setCaptchaValue(newCaptcha);
        drawCaptcha(newCaptcha);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            const newCaptcha = generateCaptcha();
            setCaptchaValue(newCaptcha);
            drawCaptcha(newCaptcha);
            setCaptchaExpirationTime(Date.now() + (5 * 60 * 1000));
        }, captchaExpirationTime - Date.now());

        return () => clearTimeout(timer);
    }, [captchaExpirationTime]);

    const loadNewCaptcha = () => {
        const newCaptcha = generateCaptcha();
        setCaptchaValue(newCaptcha);
        drawCaptcha(newCaptcha);
    }

    return (
        <div className="gap-[10px] flex w-full justify-between">
            <div>
                <div className="flex items-center gap-[8px]">
                    <div className="bg-[#FFF] rounded-[5px] w-fit h-[40px] flex items-center justify-center border border-[#D5D5D5]">
                        <canvas ref={canvasRef} width="100" height="40" className="flex items-center justify-center w-full h-full mt-[7px]"></canvas>
                    </div>
                    <div className="flex flex-col items-center gap-[2px]">
                        <button aria-label={t("aria_label.new_captcha")} title={t("aria_label.new_captcha")}  type="button" className="min-w-[18px] h-[18px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD]" onClick={loadNewCaptcha}>
                            <AutoNew />
                        </button>
                        <button aria-label={t("aria_label.audio_captcha")} title={t("aria_label.audio_captcha")} type="button" className="min-w-[18px] h-[18px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD]" onClick={() => speakCaptcha(captchaValue)}>
                            <Speaker />
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-[4px]">
                <input
                    type="text"
                    id="captchaInput"
                    name="captchaInput"
                    className="rounded-[10px] focus:!border-[#999] hover:border-[#999] bg-[#F4F4F9] border border-[#555] border-opacity-[0.2] h-[40px] px-[15px] xxl:px-[17px] text-secondaryText text-xs sm:text-sm leading-snug max-w-[170px]"
                    value={captchaInput}
                    onChange={(e: any) => { setCaptchaInput(e.target.value); setCaptchaError(""); }}
                    placeholder={t("captcha.placeholder")}
                    autoComplete="off"
                />
                {!!captchaError && <div className="text-red-500 text-xs">{captchaError}</div>}

            </div>
        </div>
    );
};

export default Captcha;