/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";

const ProfileCreated = () => {
    const profileOpen: boolean = useSelector((state: any) => state.data.profileOpen);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const ref1: any = useRef();

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref1.current && !ref1.current.contains(event.target)) {
				dispatch(dataActions.setFeedbackOpen(false))
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref1]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setProfileOpen(false))
        }
    };

    return (
        <>
            {profileOpen &&
                <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[rgba(203,203,203,0.40)] backdrop-blur-[7.5px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative my-6 md:mx-auto bg-white rounded-[20px] flex flex-col lg:flex-row w-[320px] sm:w-[600px] md:w-[745px] lg:w-[906px] h-full lg:min-h-[654] shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]" ref={ref1}>
                            <div className="w-full h-[250px] lg:h-auto lg:w-[427px] lg:max-h-full relative">
                                <img src={require("../../assets/images/login-bg.png")} alt="profile-created-bg" className="w-full h-[250px] lg:h-full object-cover rounded-t-[20px] lg:rounded-r-none lg:rounded-l-[20px] " />
                                <img src={require("../../assets/images/pattern3.png")} alt="pattern" className="absolute left-0 bottom-0 lg:rounded-bl-[20px]" />
                            </div>

                            <div className="flex flex-col relative w-full lg:w-[calc(100%-427px)] lg:max-h-full items-center justify-center p-[20px] sm:p-[30px] lg:p-[47px]">
                                <img src={require("../../assets/images/success.gif")} alt="success-gif" className="w-[132px] h-[132px] object-cover" />
                                <h1 className="text-primary dark:!text-primary text-xl sm:text-2xl 1xl:text-3xl font-bold font-caudex text-center pt-[29px]">{t("auth.profile_created_title")}</h1>
                                <p className="text-[#606060] text-xs sm:text-sm font-normal pt-[4px] text-center">{t("auth.profile_created_desc")}</p>

                                <button className="rounded-[10px]  h-[40px] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold bg-primary mt-[54px] disabled:opacity-[0.3] disabled:cursor-not-allowed w-full  " onClick={() => { dispatch(dataActions.setLoginOpen(true)); dispatch(dataActions.setProfileOpen(false)); }}>{t("auth.complete_profile")}</button>
                                <button className="w-full flex items-center justify-center text-[#606060] font-normal text-sm mt-[12px]  " onClick={() => dispatch(dataActions.setProfileOpen(false))}>{t("auth.countinue_to_web")}</button>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default ProfileCreated;