export const useSelectSecondary = (arrow: any): any => {
    let styles: any = {
      menu: (provided: any, state: any) => ({
        ...provided,
        zIndex: "20",
        borderRadius: "10px",
        background: "#fff",
        boxShadow: "0px 10px 32px -4px rgba(24, 39, 75, 0.10), 0px 6px 14px -6px rgba(24, 39, 75, 0.12)"
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        fontSize: "0.875rem",
        fontWeight: "400",
        color: state.isSelected ? "#FF4040" : state.isFocused ? "#5A5A5A" : "#5A5A5A",
        backgroundColor: state.isSelected ? "#EDEDED" : state.isFocused ? "#EDEDED" : "#fff",
        cursor: "pointer",
        border: "none",
        "&:hover": {
          backgroundColor: state.isDisabled ? "normal" : "#EDEDED",
          border: "none",
          color: state.isDisabled ? "#686868" : "#686868",
        },
      }),
      menuList: (provided: any, state: any) => ({
        ...provided,
        borderRadius: "10px"
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: "#2D2D2D",
        fontSize: "0.875rem",
        fontWeight: "400"
      }),
      valueContainer: (provided: any, state: any) => ({
        ...provided,
        padding: "0px 10px"
      }),
      dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? "#2D2D2D" : "#2D2D2D",
        fontSize: "0.875rem",
        fontWeight: "400",
        "&:hover": {
          color: state.isFocused ? "#2D2D2D" : "#2D2D2D",
        },
        padding: 0,
        paddingRight: "10px",
        display: !arrow ? "none" : "block",
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        display: "none",
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #999" : "1px solid #5553",
        backgroundColor: state.isFocused ? "#F4F4F9" : "#F4F4F9",
        borderRadius: state.isFocused ? "6px" : "6px",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          border: "1px solid #999",
        },
        cursor: !arrow ? "text" : "pointer",
        minHeight: '40px',
      }),
      input: (provided: any, state: any) => ({
        ...provided,
        color: "#2D2D2D",
        fontSize: "0.875rem",
        fontWeight: "400"
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: "#2D2D2D",
        fontSize: "0.875rem",
        fontWeight: "400",
      }),
    };
  
    return styles;
  };
  
  export default useSelectSecondary;