import { LoaderIcon } from '../svg/AllSvg';

const Loader: React.FC<any> = () => {
    return (
        <div className='w-full h-screen flex items-center justify-center'>
            <LoaderIcon />
        </div>
    );
};

export default Loader;