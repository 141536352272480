import Cookies from 'js-cookie';
import { url } from './url';

export const removeCookie = () => {
    // session
    Cookies.remove('sessionTimeout', {
        domain: url?.domains?.main,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('sessionTimeout', {
        domain: url?.domains?.lucknow,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('sessionTimeout', {
        domain: url?.domains?.kanpur,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('sessionTimeout', {
        domain: url?.domains?.agra,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    // userdetail
    Cookies.remove('userdetail', {
        domain: url?.domains?.main,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('userdetail', {
        domain: url?.domains?.lucknow,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('userdetail', {
        domain: url?.domains?.kanpur,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('userdetail', {
        domain: url?.domains?.agra,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    // token
    Cookies.remove('token', {
        domain: url?.domains?.main,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('token', {
        domain: url?.domains?.lucknow,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('token', {
        domain: url?.domains?.kanpur,
        path: '/',
        secure: true,
        sameSite: 'None'
    });

    Cookies.remove('token', {
        domain: url?.domains?.agra,
        path: '/',
        secure: true,
        sameSite: 'None'
    });
}