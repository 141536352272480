/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { CheckEye, CloseBtn, Eye } from "../svg/AllSvg";
import useAxios from "../../shared/hooks/use-axios";
import { url } from "../../shared/utils/url";
import Captcha from "../form/Captcha";

const Register = () => {
    const registerOpen: boolean = useSelector((state: any) => state.data.registerOpen);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const feedbackFormData: any = useSelector((state: any) => state.data.feedbackFormData);
    const feedback: boolean = useSelector((state: any) => state.data.feedback);
    const [hide, setHide] = useState<boolean>(true);
    const [confirmhide, setConfirmHide] = useState<boolean>(true);
    const [submit, setSubmit] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [captchaValue, setCaptchaValue] = useState<string>("")
    const [captchaError, setCaptchaError] = useState<string>("")
    const [captchaExpirationTime, setCaptchaExpirationTime] = useState(Date.now() + (5 * 60 * 1000));
    const [captchaInput, setCaptchaInput] = useState("");
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const ref1: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref1.current && !ref1.current.contains(event.target)) {
                dispatch(dataActions.setRegisterOpen(false))
                dispatch(dataActions.setFeedBack(false))
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setRegisterOpen(false))
            dispatch(dataActions.setFeedBack(false))
        }
    };

    const handlePassword = () => {
        setHide(!hide);
    };

    const handleConfirmPassword = () => {
        setConfirmHide(!confirmhide);
    };

    const registerObj: any = {
        first_name: feedback ? (!!feedbackFormData?.first_name ? feedbackFormData?.first_name : "") : "",
        last_name: feedback ? (!!feedbackFormData?.last_name ? feedbackFormData?.last_name : "") : "",
        email: feedback ? (!!feedbackFormData?.email ? feedbackFormData?.email : "") : "",
        password: "",
        password2: ""
    };

    const registerSchema = yup.object().shape({
        first_name: yup.string().required(t("auth.form.validation.first_name")),
        last_name: yup.string().required(t("auth.form.validation.last_name")),
        email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
        password: yup.string().matches(/^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t("auth.form.validation.wrong_password")).required(t("auth.form.validation.password")),
        password2: yup.string().oneOf([yup.ref("password"), ""], t("auth.form.validation.match_password")).required(t("auth.form.validation.confirm_password"))
    });

    const handleSubmit = async (values: any) => {
        //submit
        if (!!!captchaValue) {
            setCaptchaError(t("auth.form.validation.captcha"))
            return;
        }

        if (captchaInput !== captchaValue) {
            setCaptchaError(t("auth.form.validation.match_captcha"));
            return;
        }

        try {
            setSubmit(true)
            let obj = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                password: values.password,
                password2: values.password2
            }
            const res: any = await useAxios.post(`/${lang + url?.registration}`, obj);

            if (res && res?.status === 201) {
                setSubmit(false)
                dispatch(dataActions.setProfileOpen(true))
                dispatch(dataActions.setRegisterOpen(false))
                dispatch(dataActions.setFeedBack(false))
            }
        } catch (error: any) {
            setSubmit(false)
            if (error && error?.response?.status === 400) {
                setError(error?.response?.data?.email?.toString())
            }
            console.error("error while registration", error);
        }
    }

    return (
        <>
            {registerOpen &&
                <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[rgba(203,203,203,0.40)] backdrop-blur-[7.5px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative my-6 md:mx-auto bg-white rounded-[20px] flex flex-col lg:flex-row w-[320px] sm:w-[600px] md:w-[745px] lg:w-[906px] h-full lg:h-[650px] shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]" ref={ref1}>
                            <div className="w-full h-[250px] lg:h-auto lg:w-[427px] lg:max-h-full relative">
                                <img src={require("../../assets/images/login-bg.png")} alt="register-bg" className="w-full h-[250px] lg:h-full object-cover rounded-t-[20px] lg:rounded-r-none lg:rounded-l-[20px] " />
                                <img src={require("../../assets/images/pattern3.png")} alt="pattern" className="absolute left-0 bottom-0 lg:rounded-bl-[20px]" />
                            </div>

                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="z-[1] cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => { dispatch(dataActions.setRegisterOpen(false)); dispatch(dataActions.setFeedBack(false)) }}>
                                <CloseBtn />
                            </button>

                            <Formik
                                initialValues={registerObj}
                                validationSchema={registerSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, handleChange, handleBlur, errors }) => (
                                    <Form className="flex flex-col relative w-full lg:w-[calc(100%-427px)] lg:max-h-full items-start justify-center  p-[20px] sm:p-[30px] lg:p-[47px]">
                                        <h1 className="text-primary text-xl sm:text-2xl 1xl:text-3xl font-bold font-caudex">{t("auth.registerTitle")}</h1>
                                        <p className="text-[#606060] text-xs sm:text-sm font-normal pt-[4px]">{t("auth.registerDesc")}</p>

                                        <div className="flex flex-col gap-[12px] pt-[30px] w-full">
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[10px]">
                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-darkGray text-xs font-normal" htmlFor="first_name">{t("auth.form.field.first_name")}</label>
                                                    <input className="rounded-[10px] bg-[#F4F4F9] hover:border-[#999] focus:border-[#999] border border-[#ECECEC] h-[40px] px-[10px]   text-secondaryText text-xs sm:text-sm font-normal disabled:hover:!border-[#ECECEC] disabled:opacity-[0.6]" type="text" name="first_name" value={values?.first_name} onBlur={handleBlur} onChange={handleChange} autoFocus={true} autoComplete="off" />
                                                    <ErrorMessage
                                                        name="first_name"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-darkGray text-xs font-normal" htmlFor="first_name">{t("auth.form.field.last_name")}</label>
                                                    <input className="rounded-[10px] bg-[#F4F4F9] hover:border-[#999] focus:border-[#999] border border-[#ECECEC] h-[40px] px-[10px]   text-secondaryText text-xs sm:text-sm font-normal disabled:hover:!border-[#ECECEC] disabled:opacity-[0.6]" type="text" name="last_name" value={values?.last_name} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />
                                                    <ErrorMessage
                                                        name="last_name"
                                                        component="div"
                                                        className="text-red-500 text-xs"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-[4px]">
                                                <label className="text-darkGray text-xs font-normal" htmlFor="first_name">{t("auth.form.field.email")}</label>
                                                <input className="rounded-[10px] bg-[#F4F4F9] hover:border-[#999] focus:border-[#999] border border-[#ECECEC] h-[40px] px-[10px]   text-secondaryText text-xs sm:text-sm font-normal disabled:hover:!border-[#ECECEC] disabled:opacity-[0.6]" type="email" name="email" value={values?.email} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="text-red-500 text-xs"
                                                />
                                                {(error && !!!errors?.email) && <div className="text-red-500 text-xs">{t("auth.form.validation.exist_email")}</div>}
                                            </div>

                                            <div className="flex flex-col gap-[4px]">
                                                <label className="text-darkGray text-xs font-normal" htmlFor="password">{t("auth.form.field.password")}</label>
                                                <div className="rounded-[10px] bg-[#F4F4F9] hover:border-[#999] focus:border-[#999] border border-[#ECECEC] h-[40px] pl-[10px] pr-[40px] w-full relative flex items-center">
                                                    <input className="bg-[#F4F4F9] w-full text-secondaryText text-xs sm:text-sm font-normal h-full" type={hide ? "password" : "text"} name="password" value={values?.password} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />

                                                    <div className="absolute right-[10px] top-[12px] cursor-pointer" onClick={handlePassword}>
                                                        {hide ? <CheckEye /> : <Eye />}
                                                    </div>
                                                </div>
                                                <ErrorMessage
                                                    name="password"
                                                    component="div"
                                                    className="text-red-500 text-xs"
                                                />
                                            </div>

                                            <div className="flex flex-col gap-[4px] mb-[20px]">
                                                <label className="text-darkGray text-xs font-normal" htmlFor="confirm_password">{t("auth.form.field.confirm_password")}</label>
                                                <div className="rounded-[10px] bg-[#F4F4F9] hover:border-[#999] focus:border-[#999] border border-[#ECECEC] h-[40px] pl-[10px] pr-[40px] w-full relative flex items-center">
                                                    <input className="bg-[#F4F4F9] w-full   text-secondaryText text-xs sm:text-sm font-normal h-full" type={confirmhide ? "password" : "text"} name="password2" value={values?.password2} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />

                                                    <div className="absolute right-[10px] top-[12px] cursor-pointer" onClick={handleConfirmPassword}>
                                                        {confirmhide ? <CheckEye /> : <Eye />}
                                                    </div>
                                                </div>
                                                <ErrorMessage
                                                    name="password2"
                                                    component="div"
                                                    className="text-red-500 text-xs"
                                                />
                                            </div>

                                            <Captcha captchaValue={captchaValue} setCaptchaValue={setCaptchaValue} captchaError={captchaError} setCaptchaError={setCaptchaError} captchaExpirationTime={captchaExpirationTime} setCaptchaExpirationTime={setCaptchaExpirationTime} captchaInput={captchaInput} setCaptchaInput={setCaptchaInput} />
                                        </div>

                                        <button type="submit" className="rounded-[10px]  min-h-[40px] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold bg-primary mt-[20px] disabled:opacity-[0.3] disabled:cursor-not-allowed w-full  hover:shadow-[0_0_6px_0px_#61095A] focus:shadow-[0_0_6px_0px_#61095A] " disabled={!submit ? false : true} >{t("auth.registerBtn")}</button>

                                        {/* <div className="flex flex-col w-full items-center justify-center py-[25px]">
                                            <p className="text-xs text-[#606060] font-normal">{t("auth.continue_with")}</p>

                                            <div className="flex items-center gap-[8px] pt-[8px]">
                                                <button type="button" className="cursor-pointer  focus:shadow-[0_0_6px_0px_#32435F] focus:rounded-full">
                                                    <Google />
                                                </button>

                                                <button type="button" className="cursor-pointer  focus:shadow-[0_0_6px_0px_#32435F] focus:rounded-full">
                                                    <FacebookLogin />
                                                </button>
                                            </div>
                                        </div> */}

                                        <div className="flex items-center justify-center w-full pt-[8px]">
                                            <p className="text-[#606060] text-xs font-normal">{t("auth.already_account")} &nbsp;<button type="button" className="text-primary text-xs font-normal cursor-pointer  focus:border-b focus:border-primary" onClick={() => { dispatch(dataActions.setLoginOpen(true)); dispatch(dataActions.setRegisterOpen(false)); }}>{t("auth.login")}</button></p>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default Register;