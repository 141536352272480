import axios from "axios";
import { url } from "../utils/url";
import { removeCookie } from "../utils/removeCookie";
import Cookies from 'js-cookie';

const useAxios = axios.create({
    baseURL: url?.baseUrl
});

const requestHandler = (req: any): any => {
    if (Cookies.get('token')) {
        // eslint-disable-next-line
        req.headers['Authorization'] = 'Token' + ' ' + Cookies.get('token');
    }
    return req;
};

const responseHandler = (res: any): any => {
    return res;
};

const errorHandler = (error: any): any => {
    if (error && error?.response?.status === 401) {
        removeCookie()
    }
    return Promise.reject(error);
};

useAxios.interceptors.request.use(
    (req: any) => requestHandler(req),
    (error: any) => errorHandler(error)
);

useAxios.interceptors.response.use(
    (res: any) => responseHandler(res),
    (error: any) => errorHandler(error)
);

export default useAxios;