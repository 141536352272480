import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { removeCookie } from '../utils/removeCookie';

interface AuthState {
    isLogin: boolean,
    user: any
}

const initialState: AuthState = {
    isLogin: Cookies.get("sessionTimeout") as any ? true : false,
    user: (Cookies.get('userdetail') as any) ? JSON.parse(Cookies.get('userdetail') as any) : null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.isLogin = true;
        },
        logout: (state) => {
            state.isLogin = false;
            removeCookie()

        },
        userDetail: (state, action) => {
            state.user = action.payload;
        }
    }
});

export default authSlice;
export const authActions = authSlice.actions;